import React, { useEffect } from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import DataInformation from "./components/dataInformation/DataInformation";
import Creation from "./components/creation/Creation";
import Howto from "./components/howto/Howto";
import Me from "./components/me/Me";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Imprint from "./components/imprint/Imprint";
import Privacy from "./components/privacy/Privacy";
import Blog from "./components/blog/Blog";
import BlogItem from "./components/blogItem/BlogItem";
import Adblocker from "./components/adblocker/Adblocker";


import "bootstrap/dist/css/bootstrap.css";
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  useLocation,
} from "react-router-dom";

const Index = () => (
  <>
  <Adblocker />
    <Header />
    <Nav />
    <About />
    <DataInformation />
    <Creation />
    <Howto />
    <Me />
    <Contact />
    <Footer />
  </>
);

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <main>
      <Outlet />
    </main>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Index />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="Blog/:slug" element={<BlogItem />} />
          <Route path="Imprint" element={<Imprint />} />
          <Route path="Privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
