import React from "react";
import "./adblocker.css";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import { BsShieldExclamation } from "react-icons/bs";


const SomeFunctionalComponent = () => {
return (
<AdBlockDetectedWrapper>
  <div className="adblocker">
    <div className="container">
      <div className="row justify-content-center d-flex">
        <div className="col-md-8 col-12 bg-white adblocker-section d-flex align-items-center">
          <div className="row d-flex justify-content-center">
            <div className="col-12 text-center display-5">
            <BsShieldExclamation />
            </div>
            <div className="col-12 adblocker-title text-center display-4 mt-2">
              AdBlocker Detected!
            </div>
            <div className="col-12 adblocker-title text-center lead mt-2">
              Please whitelist my Website ❤️ 
            </div>
            <div className="col-12 col-md-12 adblocker-title text-center lead mt-5">
            <a href="https://mbox-splitter.com" class="adblocker-submit-button  py-3" role="button" aria-pressed="true">I've disabled adblocker</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</AdBlockDetectedWrapper>
);
};
export default SomeFunctionalComponent;